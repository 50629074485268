import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";

export const frontmatter = {
  title: "Día 86",
  week: "Semana 13",
  day: "07",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-07",
  path: "/cronologia/semana-13#dia-07-jun/",
};

const Day86 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          Record de casos diarios a nivel mundial con 136.000 nuevos casos
          declarados
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 2.053 casos mediante pruebas PCR. En ese mismo
          periodo, 148 personas han requerido hospitalización (7,2% de los
          diagnósticos), de los que 14 han sido ingresos en la UCI, y se han
          producido 72 fallecimientos.
        </ModText>
        <ModText>
          En España se han realizado más de 2,8 millones de pruebas PCR desde
          que comenzó la epidemia por COVID-19. Además, en la última semana, las
          comunidades autónomas han aumentado su capacidad para efectuar este
          tipo de pruebas diagnósticas en un 11%.
        </ModText>
        <ModImage
          src="/images/svg/laboratorio-covid-test.svg"
          alt="laboratorio test covid-19"
        />

        <ModText>
          Junto a las pruebas diagnósticas PCR practicadas, las comunidades
          autónomas también han notificado la realización de 1.642.458 test
          rápidos de anticuerpos, lo que supone una tasa de 34,9 por cada 1.000
          habitantes y un incremento del 8% respecto a la semana anterior.
        </ModText>
        <ModText>
          Las pruebas realizadas, por CCAA, se pueden consultar en el siguiente{" "}
          <InlineLink link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/COVID-19_pruebas_diagnosticas_04_06_2020.pdf">
            enlace.
          </InlineLink>
        </ModText>
        <ModGraph
          name="07-06-world-confirmados"
          alt="resto del mundo confirmados covid-19"
        />
        <ModFootnote>
          EE.UU. no se ha incluido en el gráfico para favorecer la comprensión
          del impacto en otros países.
        </ModFootnote>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day86;
